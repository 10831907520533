"use client";

import { useEffect, useRef, useState } from "react";

export default function IntroIframe() {
  const [display, setDisplay] = useState(() => {
    return true;
    //TODO: make this active
    // const saved = localStorage.getItem("viewed");
    // if (saved) {
    //   return false;
    // } else {
    //   return true;
    // }
  });
  useEffect(() => {
    const handler = (ev: MessageEvent<{ type: string; message: string }>) => {
      console.log("ev", ev);

      if (typeof ev.data !== "object") return;
      if (!ev.data.type) return;
      if (ev.data.type !== "button-click") return;
      if (!ev.data.message) return;

      console.log("detected!");
      localStorage.setItem("viewed", "true");
      setTimeout(() => {
        setDisplay(false);
      }, 1500);
    };

    window.addEventListener("message", handler);

    // Don't forget to remove addEventListener
    return () => window.removeEventListener("message", handler);
  }, []);
  return (
    <div
      className={`fixed left-0 top-0 z-50 h-full w-full bg-[#0a0222] ${
        display
          ? ""
          : "pointer-events-none opacity-0 transition-opacity duration-700 ease-in"
      }`}
    >
      <iframe
        // onClick={() => {
        //   setDisplay(false);
        // }}
        className="h-full w-full"
        src="/intro/satnam-new.html"
      />
    </div>
  );
}
