"use client"
const LogRocket = require('logrocket');

const LogRocketLoader = () => {

    //You can show in the console the GA_TRACKING_ID to confirm
    if (typeof window !== 'undefined') {
      LogRocket.init('satnam/satnam-stream');
      // plugins should also only be initialized when in the browser
    }
    return <div></div>
    
}

export default LogRocketLoader