"use client";

import Button from "../ui/Button";
import Input from "../ui/Input";
import Avatar from "./Avatar";
import { userSchema } from "./profileSchema";
import { Database } from "@/types_db";
import Image from "next/image";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import Button2 from "../ui/Button2/Button2";
import { createClient } from "@/utils/supabase/client";

type Profile = Database["public"]["Tables"]["profiles"]["Row"] | null;

export default function ProfileForm() {
  const [formData, setFormData] = useState<Profile>({
    id: "",
    display_name: "",
    avatar_url: "",
    created_at: "",
    updated_at: "",
  });
  const { push } = useRouter();
  const pathname = usePathname();

  const [email, setEmail] = useState("");
  const [initEmail, setInitEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  const supabase = createClient();

  //fetch current data if exists
  useEffect(() => {
    async function fetchData() {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) {
        throw "Error fetching profile data.";
      }
      setInitEmail(user.email ?? "");
      setEmail(user.email ?? "");

      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", user.id)
        .single();

      setFormData(data);
      console.log(data);
      console.log(user);
      if (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [supabase]);

  // useEffect(() => {
  //   console.log(pathname);
  // }, [pathname]);

  useEffect(() => {
    if (email !== initEmail) {
      setEmailMessage(
        "an email will be sent to this address to confirm your new email address"
      );
    } else {
      setEmailMessage("");
    }
  }, [email, initEmail]);

  async function updateUserEmail() {
    //check email address for validity? Or does supabase handle this?
    //check if email has changed
    if (initEmail !== email) {
      try {
        await supabase.auth.updateUser({
          email: email,
        });
      } catch (err) {
        console.log(err);
        setError("error updating email. Please try again later");
      }
    }
  }

  async function updateProfileImage(url: string) {
    if (!formData) return;
    await supabase
      .from("profiles")
      .update({
        avatar_url: url,
        updated_at: new Date().toISOString(),
      })
      .eq("id", formData.id);
    setFormData({ ...formData, avatar_url: url });
  }

  async function submitForm() {
    setError("");
    setMessage("");
    setEmailMessage("");
    let valid = await validateUserName();
    if (!valid) return;
    if (!formData) return;
    updateUserEmail();

    await supabase
      .from("profiles")
      .update({
        display_name: formData.display_name,
        updated_at: new Date().toISOString(),
      })
      .eq("id", formData.id);
    setMessage("Updated");
    setTimeout(() => {
      push(pathname);
    }, 1000);
  }

  async function validateUserName() {
    //TODO add server side restrictions (postgres)
    try {
      let data = await userSchema.validate({ ...formData, email: email });
      console.log(data);
      return true;
    } catch (err: any) {
      setError(err.errors[0]);
      return false;
    }
  }

  return (
    <div>
      <div>
        <label htmlFor="email">Email (required)</label>
        <input
          name="email"
          value={email}
          className="my-5 w-full border-b border-bgGrey bg-bgLGrey text-black"
          onChange={(e) => setEmail(e.target.value)}
        />
        <div>{emailMessage}</div>
      </div>

      <div>
        <label htmlFor="displayName">Display Name (required)</label>
        <input
          name="displayName"
          className="my-5 w-full border-b border-bgGrey bg-bgLGrey text-black"
          value={formData?.display_name}
          onChange={(e) => {
            if (!formData) return;
            setFormData({ ...formData, display_name: e.target.value });
          }}
        />
      </div>
      <div>
        <label htmlFor="profileImage">Profile Image (optional)</label>

        {formData && (
          <Avatar
            url={formData.avatar_url}
            size={150}
            onUpload={(event, url) => {
              if (!formData) return;
              updateProfileImage(url);
            }}
          />
        )}
      </div>
      <div className="mx-auto w-full pt-4 text-center">
        <Button2 onClick={() => submitForm()}>Continue</Button2>
      </div>
      <div>{message}</div>

      <div>{error}</div>
    </div>
  );
}
