"use client"
import Link from "next/link";
import Button2 from "../ui/Button2/Button2";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const url =
  "https://instagram.us21.list-manage.com/subscribe/post?u=00b74ce0d9f56441c31b51a1f&id=82bd2c02b1&f_id=0021e7e6f0";

// simplest form (only email)
const CustomForm = ({ status, onValidated, full }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value
    });

  return (
    <div className="">
      {full &&<div className="text-textGrey">
      Subscribe to the satnam.studio newsletter to recieve exclusive information on new offerings, retreats and even more goodies.
      </div>}
      {status === "sending" && <div style={{ color: "white" }}>sending...</div>}
      {status === "error" && (
        <div style={{ color: full? "white" : "black" }}>
          Error submitting. Please check your email address and try again.
        </div>
      )}
      {status === "success" && (
        <div style={{ color: full? "white" : "black" }}>Thank you for subscribing</div>
      )}
      <br />
      <input
        ref={(node) => (email = node)}
        type="email"
        placeholder="Email"
        className="text-black uppercase bg-bgLGrey border-b border-bgGrey my-8 w-full"
      />
      <div className={`w-full text-center ${full ? "py-8" : ""}`}><Button2 onClick={submit}>Send</Button2></div>
      <br />
      {full && (<>
      <div className="text-sm text-textGrey">By signing up to our newsletter, you agree to stay in touch with satnam.studio but we promise not to send you spam! For more information about how we use your info and your rights refer to our <Link href="privacy-policy">PRIVACY POLICY</Link>.</div></>)}
    </div>
  );
};

// // use the render prop and your custom form
const MailchimpSignup = ({full = true}) => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <CustomForm
        status={status}
        message={message}
        full={full}
        onValidated={(formData) => subscribe(formData)}
        className
      />
    )}
  />
);

export default MailchimpSignup;
