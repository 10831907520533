"use client";
import { Database } from "@/types_db";
import { User } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import Button2 from "../ui/Button2/Button2";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { createClient } from "@/utils/supabase/client";

import ProfileForm from "../profile/ProfileForm";
import Avatar from "../profile/Avatar";

export default function AccountWidget() {
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<
    Database["public"]["Tables"]["profiles"]["Row"] | null
  >(null);
  const [open, setOpen] = useState(false);
  const [subscription, setSubscription] = useState<any>();

  const router = useRouter();
  const searchParams = useSearchParams();

  const supabase = createClient();

  // supabase.auth.onAuthStateChange(async () => {
  //   console.log("auth state change 2");
  // });

  useEffect(() => {
    if (searchParams.get("account")) {
      setOpen(true);
    }
  }, [searchParams]);

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      console.log("auth state change", event);
      if (event === "SIGNED_OUT") {
        console.log("event signed out");
        setUser(null);
        setSubscription(null);
      }
      if (event === "SIGNED_IN" || event === "INITIAL_SESSION") {
        console.log("event signed in");
        if (session?.user) {
          setUser(session?.user);
          try {
            const { data: subscription } = await supabase
              .from("subscriptions")
              .select("*, prices(*, products(*))")
              .in("status", ["trialing", "active"])
              .maybeSingle()
              .throwOnError();
            setSubscription(subscription);
            console.log("subscription", subscription);
          } catch (error) {
            console.error("Error:", error);
          }
        }
      }
    });
  }, []);

  type Profile = Database["public"]["Tables"]["profiles"]["Row"] | null;

  const [email, setEmail] = useState("");
  const [initEmail, setInitEmail] = useState("");
  const [formData, setFormData] = useState<Profile>({
    id: "",
    display_name: "",
    avatar_url: "",
    created_at: "",
    updated_at: "",
  });

  const { push } = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    async function fetchData() {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) {
        throw "Error fetching profile data.";
      }
      setInitEmail(user.email ?? "");
      setEmail(user.email ?? "");

      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", user.id)
        .single();

      setFormData(data);
      console.log(data);
      console.log(user);
      if (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [supabase]);

  async function updateProfileImage(url: string) {
    if (!formData) return;
    await supabase
      .from("profiles")
      .update({
        avatar_url: url,
        updated_at: new Date().toISOString(),
      })
      .eq("id", formData.id);
    setFormData({ ...formData, avatar_url: url });
  }

  if (!user) {
    return;
  } else {
    return (
      <div className="fixed bottom-0 right-0 w-full max-w-[500px] bg-textGrey z-50  text-center font-bold text-white md:mr-10">
        <div
          onClick={() => {
            setOpen(!open);
            push(pathname, { scroll: false });
          }}
          className="flex justify-between px-5 py-5"
        >
          <div className="uppercase">{profile?.display_name ?? "Account"}</div>
          <div className="uppercase">
            {open ? (
              <svg
                className="h-6 w-6 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 8"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 8"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7"
                />
              </svg>
            )}
          </div>
        </div>
        {open && (
          <div className="border bg-bgGrey2 text-center text-black">
            {formData && (
              <div className="mx-auto w-full">
                <Avatar
                  url={formData.avatar_url}
                  size={150}
                  onUpload={(event, url) => {
                    if (!formData) return;
                    updateProfileImage(url);
                  }}
                />
              </div>
            )}
            {email && email}
            {subscription ? (
              <div className="py-4">
                {`You are currently on the ${subscription?.prices?.products?.name} plan.`}
                <Button2
                  onClick={async () => {
                    const res = await fetch("/api/create-portal-link", {
                      method: "POST",
                    });
                    let data = await res.json();

                    router.push(data.url);
                  }}
                >
                  Change/Cancel Plan
                </Button2>
              </div>
            ) : (
              <div className="py-4">
                You are not currently subscribed to any plan.
                <Button2 url="/subscribe">Subscribe Now</Button2>
              </div>
            )}
            <Button2
              className="py-4 uppercase"
              onClick={async () => {
                await supabase.auth.signOut();
                setTimeout(() => {
                  window.location.href = "/";
                }, 1000);
              }}
            >
              Log out
            </Button2>
          </div>
        )}
      </div>
    );
  }
}
