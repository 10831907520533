import(/* webpackMode: "eager" */ "/vercel/path0/app/GoogleAnalytics.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/IntroIframe.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/LogRocketLoader.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/account/AccountWidget.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/modal/ModalDisplay.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-recaptcha-v3/lib/ReCaptcha.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-recaptcha-v3/lib/ReCaptchaProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-recaptcha-v3/lib/useReCaptcha.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-recaptcha-v3/lib/withReCaptcha.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"700\",\"200\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/main.css")