"use client";

import LoadingDots from "@/components/ui/LoadingDots";
import { getURL } from "@/utils/helpers";
import { createClient } from "@/utils/supabase/client";
import { usePathname } from "next/navigation";
import { useState } from "react";

export default function AuthUI() {
  const supabase = createClient();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const pathname = usePathname();

  console.log(`${getURL()}subscribe`);
  return (
    <div className="flex flex-col space-y-4 text-textGrey">
      <label>Email Address</label>

      <input
        className="my-8 w-full border-b border-bgGrey bg-bgLGrey text-black"
        placeholder="email"
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        autoComplete="true"
        name="email"
      />

      {!loading ? (
        <button
          className="uppercase"
          onClick={async () => {
            setMessage("");
            setLoading(true);

            console.log("url: ", getURL());
            const { data, error } = await supabase.auth.signInWithOtp({
              email: email,
              options: {
                emailRedirectTo: `http://localhost:3001/auth/callback`,
              },
            });
            setLoading(false);
            if (!error) {
              setMessage("Please check your email for the link to login.");
            } else {
              console.log(error);
              setMessage(error.message);
            }
          }}
        >
          Send a magic link to login
        </button>
      ) : (
        <div className="w-full text-center">
          <LoadingDots />
        </div>
      )}
      <div>{message}</div>
      {/* <Auth
        supabaseClient={supabase}
        providers={[]}
        redirectTo={`${getURL()}/auth/callback`}
        magicLink={true}
        appearance={{
          theme: ThemeSupa,
          variables: {
            default: {
              colors: {
                brand: '#404040',
                brandAccent: '#52525b'
              }
            }
          }
        }}
        theme="dark"
      /> */}
    </div>
  );
}
