import {
  DetailedHTMLProps,
  HTMLAttributes,
  MouseEventHandler,
  ReactNode
} from "react";

interface Props {
  children: ReactNode;
  closeModal?: () => void;
  title: string;
}

export default function Modal({ children, closeModal, title }: Props) {
  return (
    <div
      className="modal-dismiss fixed inset-0 z-50 flex max-w-full flex-col items-center justify-center bg-bgGrey bg-opacity-50"
      onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        let clicked = e.target as HTMLInputElement;
        let dismiss = clicked.className.includes("modal-dismiss");
        // if (e.target.classList)
        closeModal && dismiss
          ? closeModal()
          : console.log("modal outer clicked");
      }}
    >
      <div className="max-w-[400px] bg-bgLGrey">
        <div className="flex justify-between bg-[#4C4643] p-6 font-[700] uppercase">
          <div>{title}</div>
          {closeModal && (
            <div onClick={() => closeModal && closeModal()}>X</div>
          )}
        </div>
        <div className="p-6">{children}</div>
      </div>
    </div>
  );
}
