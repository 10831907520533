"use client";

import { useState } from "react";
import ProfileForm from "../profile/ProfileForm";
import Button from "../ui/Button";
import Modal from "../ui/Modal/Modal";
import { redirect, usePathname, useSearchParams } from "next/navigation";
import { useRouter } from "next/navigation";
import MailchimpSignup from "../MailchimpSignup/MailchimpSignup";
import ContactPopup from "../contact/ContactPopup";
import AuthUI from "@/app/signin/AuthUI";
import Button2 from "../ui/Button2/Button2";

export default function ModalDisplay() {
  const searchParams = useSearchParams();
  const { push } = useRouter();
  const pathname = usePathname();
  const [message, setMessage] = useState("");

  if (searchParams.get("profile")) {
    return (
      <Modal title="Finish setting up">
        <div className="text-textGrey">
          Please finish setting up your profile before continuing.
          <ProfileForm />
        </div>
      </Modal>
    );
  }
  if (searchParams.get("purchase")) {
    return (
      <Modal title="Purchase" closeModal={() => push(pathname)}>
        {searchParams.get("purchase") === "complete" ? (
          <div className="text-textGrey">
            Thank you for your purchase.
            <br /> Please check your emails for your receipt. More details will
            follow closer to the event.
          </div>
        ) : (
          <div className="text-textGrey">
            Purchase failed.
            <br /> Your order has not been processed.
          </div>
        )}

        <Button onClick={() => push(pathname, { scroll: false })}>Close</Button>
      </Modal>
    );
  }
  if (searchParams.get("subscribe")) {
    return (
      <Modal
        closeModal={() => {
          push(pathname, { scroll: false });
          setMessage("");
        }}
        title="subscribe"
      >
        <MailchimpSignup />
        {message}
      </Modal>
    );
  }

  if (searchParams.get("contact")) {
    return (
      <Modal
        closeModal={() => {
          push(pathname, { scroll: false });
          setMessage("");
        }}
        title="send us a message"
      >
        <ContactPopup />
        {message}
      </Modal>
    );
  }

  if (searchParams.get("app-link")) {
    return (
      <Modal
        title="Download the app"
        closeModal={() => {
          push(pathname, { scroll: false });
          setMessage("");
        }}
      >
        <div className="min-w-[250px] text-center flex flex-col">
          <Button2 url="https://apps.apple.com/us/app/satnam-studio/id6446968563">
            iOS
          </Button2>
          <br />
          <Button2 url="https://play.google.com/store/apps/details?id=com.satnamstudio&hl=en&gl=US">
            Android
          </Button2>
        </div>
      </Modal>
    );
  }

  if (searchParams.get("login")) {
    return (
      <Modal
        closeModal={() => {
          push(pathname, { scroll: false });
          setMessage("");
        }}
        title="login"
      >
        <AuthUI />
        {message}
      </Modal>
    );
  }

  return;
}
