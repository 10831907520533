"use client";

import { useReCaptcha } from "next-recaptcha-v3";
import { useState } from "react";
import Button2 from "../ui/Button2/Button2";

export default function ContactPopup() {
  const [message, setMessage] = useState("");

  const [messageSent, setMessageSent] = useState(false);

  const [contact, setContact] = useState({
    name: "",
    email: "",
    message: "",
    subject: ""
  });

  const { executeRecaptcha } = useReCaptcha();

  const handleContact = async (inputContact: any) => {
    console.log("calling");
    try {
      setMessage("");

      console.log(inputContact.email);

      // const isValidEmail = emailRegex.test(contact.email);

      // console.log(isValidEmail);

      // if (!isValidEmail) {
      //   setMessage("Pslease enter a valid email address.");
      //   return;
      // }
      if (!inputContact.message) {
        setMessage("Please enter a message.");
        return;
      }
      const token = await executeRecaptcha("form_submit");
      let res = await fetch("/api/email/contact", {
        method: "POST",
        cache: "no-cache",
        body: JSON.stringify({
          message: inputContact.message,
          subject: inputContact.subject,
          email: inputContact.email,
          token: token
        })
      });

      if (!res.ok) throw "error";
      console.log(res);
      setMessage("Message sent");
      setMessageSent(true);
    } catch (err) {
      console.log(err);
      setMessage("error sending message, please try again later.");
    }
  };

  return (
    <>
      <div>
        <input
          className="mb-2 w-full  p-2 text-black"
          placeholder="name"
          value={contact.name}
          onChange={(e) =>
            setContact((current) => {
              return { ...current, name: e.target.value };
            })
          }
        />
      </div>
      <div>
        <input
          className="mb-2 w-full  p-2 text-black"
          placeholder="email"
          value={contact.email}
          onChange={(e) =>
            setContact((current) => {
              return { ...current, email: e.target.value };
            })
          }
        />
      </div>
      <div>
        <input
          className="mb-2 w-full  p-2 text-black"
          placeholder="subject"
          value={contact.subject}
          onChange={(e) =>
            setContact((current) => {
              return { ...current, subject: e.target.value };
            })
          }
        />
      </div>
      <div>
        <textarea
          className="mb-2 w-full  p-2 text-black"
          placeholder="message"
          value={contact.message}
          onChange={(e) =>
            setContact((current) => {
              return { ...current, message: e.target.value };
            })
          }
        />
      </div>

      <Button2 disabled={messageSent} onClick={() => handleContact(contact)}>
        Send
      </Button2>
      <div className="text-xs text-bgGrey">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
      <div className="text-red-700">{message}</div>
    </>
  );
}
