import LoadingDots from "../LoadingDots";
import Link from "next/link";
import { ReactNode } from "react";

type Props = WithUrl | WithOnClick;

interface BaseProps {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  style?: "light" | "dark" | "gold";
}

interface WithUrl extends BaseProps {
  url: string;
  target?: string;
  onClick?: () => void;
  scroll?: boolean;
}

interface WithOnClick extends BaseProps {
  onClick: () => void;
  target?: never;
  url?: never;
  scroll?: never;
}

export default function Button2({
  children,
  className,
  disabled = false,
  url,
  style = "dark",
  target = "_self",
  loading = false,
  onClick,
  scroll,
}: Props) {
  //if button is just appending query string then no scroll

  const renderSwitch = (children: ReactNode) => {
    if (url) {
      return (
        <Link
          target={target}
          href={url}
          scroll={scroll ? scroll : !/^\?|^\/\?/.test(url)}
        >
          {children}
        </Link>
      );
    } else if (onClick) {
      return <div onClick={() => onClick()}>{children}</div>;
    }
  };

  return renderSwitch(
    <button
      disabled={disabled}
      className={`${className} ${
        style === "light"
          ? "text-white"
          : style === "gold"
          ? "text-satnamGold"
          : "text-textGrey"
      } uppercase tracking-[2.24px] hover:underline`}
    >
      {loading ? <LoadingDots /> : children}
    </button>
  );
}
