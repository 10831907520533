"use client";

import { createClient } from "@/utils/supabase/client";
import Image from "next/image";
import { ChangeEvent, useEffect, useState } from "react";

interface Props {
  url: string | null;
  size: number;
  onUpload: (event: ChangeEvent<HTMLInputElement>, url: string) => void;
}

export default function Avatar({ url, size, onUpload }: Props) {
  function downloadImage(path: string) {
    const { data } = supabase.storage.from("avatars").getPublicUrl(path);

    return data.publicUrl;
  }

  const [avatarUrl, setAvatarUrl] = useState<null | string>(null);
  const [uploading, setUploading] = useState(false);

  const supabase = createClient();

  useEffect(() => {
    if (url) {
      let imageUrl = downloadImage(url);
      setAvatarUrl(imageUrl);
    }
    console.log(url);
  }, [url]);

  // async function downloadImage(path: string) {
  //   try {
  //     const { data, error } = await supabase.storage
  //       .from("avatars")
  //       .download(path);
  //     if (error) {
  //       throw error;
  //     }
  //     const url = URL.createObjectURL(data);
  //
  //   } catch (error) {
  //     if (error instanceof Error) {
  //       console.log("Error downloading image: ", error.message);
  //     }
  //   }
  // }

  async function uploadAvatar(event: ChangeEvent<HTMLInputElement>) {
    try {
      setUploading(true);

      if (!event.target.files || event.target.files.length === 0) {
        throw new Error("You must select an image to upload.");
      }

      const file = event.target.files[0];

      const config = {
        quality: 0.7,
        maxWidth: 250,
        maxHeight: 250,
        debug: true,
      };

      try {
        const { readAndCompressImage } = await import("browser-image-resizer");

        let resizedImage = await readAndCompressImage(file, config);

        console.log(resizedImage);

        const fileExt = file.name.split(".").pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const filePath = `${fileName}`;

        const { error: uploadError } = await supabase.storage
          .from("avatars")
          .upload(filePath, resizedImage);

        if (uploadError) {
          throw uploadError;
        }

        onUpload(event, filePath);
      } catch (error) {
        if (error instanceof Error) {
          alert(error.message);
        }
      } finally {
        setUploading(false);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  return (
    <div className="mx-auto border ">
      {avatarUrl ? (
        <Image
          src={avatarUrl}
          alt="Avatar"
          className="avatar image mx-auto"
          width={size}
          height={size}
        />
      ) : (
        <div
          className="avatar no-image"
          style={{ height: size, width: size }}
        />
      )}
      <div style={{ width: size, marginLeft: "auto", marginRight: "auto" }}>
        <label className="button primary block" htmlFor="single">
          {uploading ? "Uploading ..." : "Upload +"}
        </label>
        <input
          style={{
            visibility: "hidden",
            position: "absolute",
          }}
          type="file"
          id="single"
          accept="image/*"
          onChange={uploadAvatar}
          disabled={uploading}
        />
      </div>
    </div>
  );
}
